import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Té forma semicircular, de 5 a quasi 30 cm. S’uneixen diversos exemplars bé a la mateixa alçada o formant varis pisos superposats. La part superior pot tenir solcs semicirculars amb pèls curts i molt atapeïts de jove, quasi llisa de vell, de color marró excepte al marge que és més clar i amb frequència de color groguenc cremós més o manco clar. L’himeni està format per làmines moltes vegades anastomosades les unes amb les altres o també bifurcades, gruixudes i més aviat separades, a vegades ondulades, de color cremós groguenc de joves i marrons de velles i presentant lamel·les en el marge del bolet. Les espores són blanques en massa, llises, quasi cilíndriques, de 9-11,5 x 2,8-3,8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      